// AreaChart.js
import React, { useCallback, useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { useSelector } from "react-redux";
import { bubbleContentFormator } from "../../utils/formators";

const AreaChart = ({ data = [], metric = "close" }) => {
  const colorScheme = useSelector((state) => state.Settings.colorScheme);
  
  // Define mapping based on metric
  const metricMapping = {
    price: {
      field: "Close",
      name: "Close Price",
      color: "#0d5edb",
      annotationsColor: "danger", // Updated to match top-level key in colorScheme
    },
    volume: {
      field: "Volume",
      name: "Volume",
      color: "#f39c12", // Example color for Volume
      annotationsColor: "warning", // Ensure this exists in colorScheme
    },
    // Add more metrics if needed
  };

  const selectedMetric = metricMapping[metric] || metricMapping.price;

  const calculateData = useCallback((stock) => {
    // Use TradeDate instead of Timestamp
    const date = new Date(stock.TradeDate).getTime();
    const value = stock[selectedMetric.field];

    if (date && value !== undefined) {
      return [date, value];
    }
    return null;
  }, [selectedMetric.field]);

  const selectedData = useMemo(() => {
    if (data.length) {
      const processedData = data
        .sort((a, b) => new Date(a.TradeDate) - new Date(b.TradeDate))
        .map(calculateData)
        .filter(Boolean); // Filter out any null values


      return processedData;
    }
    // If no data is available, use an empty array
    return [];
  }, [data, calculateData]);

  const minYValue = useMemo(() => {
    const yValues = selectedData.map((item) => item[1]);
    return yValues.length ? Math.min(...yValues) : 0;
  }, [selectedData]);

  const maxYValue = useMemo(() => {
    const yValues = selectedData.map((item) => item[1]);
    return yValues.length ? Math.max(...yValues) : 0;
  }, [selectedData]);


  const chartData = useMemo(
    () => ({
      series: [{ name: selectedMetric.name, data: selectedData }],
      options: {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 500, // Consider removing if setting height in ApexCharts component
          toolbar: {
            show: false,
          },
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          min: selectedData[0]?.[0] || undefined,
          tickAmount: 6,
        },
        yaxis: {
          min: selectedMetric.field === "Volume" ? 0 : minYValue * 0.65,
          max: selectedMetric.field === "Volume" ? maxYValue * 1.2 : maxYValue * 1.05,
          labels: {
            show: true,
          },
          title: {
            text: selectedMetric.name,
          },
        },
        annotations: {
          yaxis: [
            {
              y: minYValue,
              borderColor: colorScheme[selectedMetric.annotationsColor]?.color || "#000",
              label: {
                borderColor: '#FF0000' || "#000",
                style: {
                  color: "#fff",
                  background: '#FF0000' || "#000",
                  fontSize: "15px",
                },
                text: `${bubbleContentFormator(selectedMetric.name, minYValue)}`,
              },
            },
            {
              y: maxYValue,
              borderColor: colorScheme[selectedMetric.annotationsColor]?.color || "#000",
              label: {
                borderColor: '#008000' || "#000",
                style: {
                  color: "#fff",
                  background: '#008000' || "#000",
                  fontSize: "15px",
                },
                text: `${bubbleContentFormator(selectedMetric.name, maxYValue)}`,
              },
            },
          ],
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
          style: {
            fontSize: "12px",
            fontFamily: "Helvetica, Arial, sans-serif",
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
            colorStops: [
              {
                offset: 0,
                color: selectedMetric.color,
                opacity: 0.7,
              },
              {
                offset: 100,
                color: selectedMetric.color,
                opacity: 0.9,
              },
            ],
          },
        },
        grid: {
          show: false,
        },
      },
    }),
    [selectedData, minYValue, maxYValue, colorScheme, selectedMetric]
  );

  return (
    <div id="chart">
      <ApexCharts
        options={chartData.options}
        series={chartData.series}
        type="area"
        height={260} // Ensure consistency with chartData options
      />
    </div>
  );
};

export default AreaChart;
