import React from "react";
// import { FaAngleDown, FaPlus } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";

const Modal = ({ isOpen, onClose, children, data }) => {
  return (
    <>
      {isOpen && (
        <div
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 right-0 bottom-0 left-0 z-[100] flex justify-center items-center bg-black bg-opacity-80"
        >
          <div className="relative p-4 w-full max-w-2xl">
            <div className="relative bg-[#585858]/60 backdrop-blur-md rounded-lg shadow">
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t">
             <div className="flex items-center space-x-2 flex items-center flex-wrap">
                 <div className="space-x-2 flex items-center flex-wrap">
                  {/* {data ? (
                    <>
                      <button
                        type="button"
                        className="text-xl font-medium p-2 text-white bg-[#585858] rounded-full"
                      >
                        <FaAngleDown />
                      </button>
                      <button
                        type="button"
                        className="text-xl font-medium p-2 text-white bg-[#585858] rounded-full"
                      >
                        <FaPlus />
                      </button>
                    </>
                  ) : null} */}
                  {data ? (
                    <p className="bg-[#585858] text-white w-max px-4 py-2 rounded-md flex font-semibold items-center">
                     {(data?.stockName) ?? (data.symbol || data.Symbol)} 
                    </p>
                  ) : null}
                </div>
                <div className="space-x-2 flex items-center flex-wrap ">
                  {/* {data ? (
                    <>
                      <button
                        type="button"
                        className="text-xl font-medium p-2 text-white bg-[#585858] rounded-full"
                      >
                        <FaAngleDown />
                      </button>
                      <button
                        type="button"
                        className="text-xl font-medium p-2 text-white bg-[#585858] rounded-full"
                      >
                        <FaPlus />
                      </button>
                    </>
                  ) : null} */}
                  {data ? (
                    <p className="bg-[#585858] text-green-500 text-lg m-0 w-max px-4 py-2 rounded-md flex font-semibold items-center">
                      ₦ {data.Last}
                    </p>
                  ) : null}
                </div>
             </div>
                <button
                  type="button"
                  className="text-xl font-medium p-2 text-white bg-[#585858] rounded-full"
                  onClick={onClose}
                >
                  <RxCross2 />
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
