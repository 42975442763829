import React, { createContext, useContext, useEffect, useState } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const url = "wss://www.stockbubbles.com.ng/stock-data";

  useEffect(() => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      console.log("WebSocket connection established");
      setSocket(ws); // Set the socket after connection is open

    };

    ws.onmessage = (event) => {
      console.log("Received data:", event.data);
      // Handle incoming messages if necessary
    };

    ws.onclose = () => {
      console.log("WebSocket connection closed");
      setSocket(null);
      // Optionally, you could attempt to reconnect here
    };

    ws.onerror = (error) => {
      console.error("WebSocket error observed:", error);
      // Handle the error as needed
    };

    return () => {
      ws.close(); // Clean up the socket when the provider unmounts
    };
  }, [url]);

  // Logging the socket value for debugging
  useEffect(() => {
    console.log("WebSocket context value:", socket);
  }, [socket]);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Custom hook to use WebSocket context
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (context === undefined) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};