// themeColor.js or the relevant file where themeColor is defined

export const themeColor = {
  OB_THEME: {
    name: "Yellow + Blue",
    danger: {
      name: "yellow",
      color: "#F07A00",
      textColor: "#F07A00",
    },
    success: {
      name: "blue",
      color: "#082c7f",
      textColor: "#018FFB",
    },
    successf: {
      name: "orange",
      color: "#FFA500",
      textColor: "#6495ED",
    },
    // **Added Neutral Theme for Zero Performance**
    neutral: {
      name: "lightGray",
      color: "#A52A2A", // Brown color
      textColor: "#A52A2A",
    },
  },
  RG_THEME: {
    name: "Red + Green",
    danger: { name: "red", color: "#AA3333", textColor: "#AA3333" },
    success: {
      name: "green",
      color: "#228822",
      textColor: "#39DC39",
    },
    successf: {
      name: "orange",
      color: "#FFA500",
      textColor: "#444444",
    },
    // **Added Neutral Theme for Zero Performance**
    neutral: {
      name: "yellow",
      color: "#A52A2A", // Brown color
      textColor: "#A52A2A",
    },
  },
};


export const graphValues = [
  { id: "close", name: "Price" },
  { id: "volume", name: "Volume" },
];
